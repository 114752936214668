import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { auth } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";


export default function Navbar() {

	const [user, loading] = useAuthState(auth);

  	return (
		<>
			<nav 
				className="nav"
			>
				<Link 
					to="/" 
					className="site-title"
				>
					Guitar Pilgrim
				</Link>
				<ul>
					<CustomLink to="/">Home</CustomLink>
					<CustomLink to="/Store">Store</CustomLink>
					{/* {
						user ?
							<CustomLink to="/Lessons">Lessons</CustomLink>
							: null
					} */}
					{
						user ?
							<CustomLink to="/MyCourses">My courses</CustomLink>
							: null
					}
					{/* <CustomLink to="/pricing">Pricing</CustomLink> */}
					<CustomLink to="/about">About</CustomLink>
					{ 
						user ? 
							<NavButton to="/" onClick={() => auth.signOut()}>Logout</NavButton>
							: <CustomLink to="/login">Login</CustomLink>
					}
					{ 
						! user ? 
							<CustomLink to="/Signup">Sign up</CustomLink>
							: null
					}
					<CustomLink to="/test">Test</CustomLink>
				</ul>
			</nav>
		</>
  	)
}



function CustomLink({ to, children, ...props }) {

  	const resolvedPath = useResolvedPath(to)
	const isActive = useMatch({ path: resolvedPath.pathname, end: true })

	return (
		<li 
			className={isActive ? "active" : ""}
		>
			<Link to={to} {...props}>
				{children}
			</Link>
		</li>
	)
}



/***  
	[ ] nu maar ff aparte component van gemaakt want deze werd actief (waarschijnlijk omdat ie ok naar Home verwees) (dec 23)
*/
function NavButton({ to, children, ...props }) {

  	// const resolvedPath = useResolvedPath(to)
	const isActive = false

	return (
		<li 
			className={isActive ? "active" : ""}
		>
			<Link to={to} {...props}>
				{children}
			</Link>
		</li>
	)
}